import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { apiGetSettingsForEdit, apiUpdateSettings, apiGetSettings } from 'services/HomeServices'

export const getSettings = createAsyncThunk('SettingsEdit/data/getSettings', async (data) => {
    const response = await apiGetSettingsForEdit(data)
    return response.data
})

export const updateSettings = async (data) => {
    const response = await apiUpdateSettings(data) 
    return response.data
}

export const getAllSettings = async () => {
    const response = await apiGetSettings();
    return response.data
}


const dataSlice = createSlice({
    name: 'SettingsEdit/data',
    initialState: {
        loading: false,
        settings: [],
    },
    reducers: {
    },
    extraReducers: {
        [getSettings.fulfilled]: (state, action) => {
            state.settings = action.payload
            state.loading = false
        },
        [getSettings.pending]: (state) => {
            state.loading = true
        },
       
    }
})


export default dataSlice.reducer
