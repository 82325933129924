import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import en from './lang/en.json'
import sq from './lang/sq.json'
import sr from './lang/sr.json'
import GetThemeConfig from 'configs/theme.config'

const themeConfig = GetThemeConfig();

const resources = {
    en: {
        translation: en
    },
    sq: {
        translation: sq
    },
    sr: {
        translation: sr
    }
}
i18n.use(initReactI18next).init({
    resources,
    fallbackLng: themeConfig.locale,
    lng: themeConfig.locale,
    interpolation: {
        escapeValue: false
    }
})

export const dateLocales = {
    en: () => import('dayjs/locale/en'),
    sq: () => import('dayjs/locale/sq'),
    sr: () => import('dayjs/locale/sr'),
}

export default i18n