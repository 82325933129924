import ApiService from "./ApiService"

export async function apiGetThemeConfig (data) {
    return ApiService.fetchData({
        url: '/Home/GetThemeConfig',
        method: 'get',
        data
    })
}

export async function apiUpdateThemeConfig (data) {
    return ApiService.fetchData({
        url: '/Home/UpdateThemeConfig',
        method: 'put',
        data
    })
}

export async function apiGetLangList (data) {
    return ApiService.fetchData({
        url: '/Home/GetLangList',
        method: 'get',
        data
    })
}

export async function apiGetMediaExCategory (data) {
    return ApiService.fetchData({
        url: '/Home/GetMediaExCategory',
        method: 'get',
        data
    })
}


export async function apiGetTemplates (data) {
    return ApiService.fetchData({
        url: '/Home/GetTemplates',
        method: 'get',
        data
    })
}

export async function apiGetMultiLanguage (data) {
    return ApiService.fetchData({
        url: '/Home/GetMultiLanguage',
        method: 'get',
        data
    })
}

//Settings
export async function apiGetSettingsForEdit (data) {
    return ApiService.fetchData({
        url: '/Home/GetSettingsForEdit',
        method: 'get',
        data
    })
}

export async function apiGetSettings (data) {
    return ApiService.fetchData({
        url: '/Home/GetSettings',
        method: 'get',
        data
    })
}

export async function apiGetExtraSettings (data) {
    return ApiService.fetchData({
        url: '/Home/GetExtraSettings',
        method: 'get',
        data
    })
}
export async function apiGetExtraSettingsAsync (data) {
    return ApiService.fetchData({
        url: '/Home/GetExtraSettingsAsync',
        method: 'post',
        data
    })
}

export async function apiUpdateSettings (data) {
    return ApiService.fetchData({
        url: '/Home/UpdateSettings',
        method: 'put',
        data
    })
}

export async function apiUpdateExtraSettings (data) {
    return ApiService.fetchData({
        url: '/Home/UpdateExtraSettings',
        method: 'put',
        data
    })
}

//Dashboard
export async function apiGetDashboardStatistic (data) {
    return ApiService.fetchData({
        url: '/Home/GetDashboardStatistic',
        method: 'get',
        data
    })
}


export async function apiGetDashboardStatisticCategory (data) {
    return ApiService.fetchData({
        url: '/Home/GetDashboardStatisticCategory?LayoutId=1&webLangId=1',
        method: 'get',
        data
    })
}

export async function apiGetDashboardStatisticForMonth (data) {
    return ApiService.fetchData({
        url: '/Home/GetDashboardStatisticForMonth?&webLangId=1',
        method: 'get',
        data
    })
}

